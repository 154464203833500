// Global colors
$color-gray-600: #F3F3F2;
$color-gray-900: #F2F2F2;
$color-blue-50: #3763C1;
$color-blue-100: #192A3C;
$color-blue-150 : #4B9FF9;
$color-blue-200: #5997C1;
$color-blue-300: #5997C1;
$color-blue-400: #192A3C;
$color-blue-500: #AED6FD;
$color-blue-600: #5997C1;
$color-blue-900: #5997C1;
$color-blue-1000: #EAF3FC;
$color-white-100: white;
$color-orange-100: #C9B851;
$color-green-100: #28435B;

$base-lightness: 30; // Nivel de luminosidad base para los tonos

// Cálculo del blue-200
$base-hue: 210; // Hue para el color azul
$base-saturation: 100; // Saturación para el color azul

// Cálculo del valor de luminosidad para blue-200
$blue-100-lightness: lightness($color-blue-100);
$blue-200-lightness: $base-lightness + ($blue-100-lightness - $base-lightness) / 2;

// Conversión a formato hexadecimal
// $color-blue-200: hsl($base-hue, $base-saturation, $blue-200-lightness);

// Import font
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.disclaimer {
    font-size: .85rem;
    font-weight: 300;

    p {
        text-align: center;
        margin: 1px;
    }
}

.logo {
    width: 200px;
}

.background-home {
    background: $color-gray-900;
}

.text-gray-300 {
    color: #666666 !important;
}

.text-orange-100 {
    color: $color-orange-100 !important;
}

.text-white-100 {
    color: white !important;
}

.text-blue-50 {
    color: $color-blue-50 !important;
}

.bg-gray-600 {
    background: $color-gray-600 !important;
}

.bg-gray-900 {
    background: $color-gray-900 !important;
}

.bg-blue-100 {
    background: $color-blue-100 !important;
}

.bg-blue-150 {
    background: $color-blue-150 !important;
}

.bg-blue-200 {
    background: $color-blue-200 !important;
}

.bg-blue-300 {
    background: $color-blue-300 !important;
}

.bg-blue-400 {
    background: $color-blue-400 !important;
}

.bg-blue-500 {
    background: $color-blue-500 !important;
}

.bg-blue-600 {
    background: $color-blue-600 !important;
}

.bg-blue-900 {
    background: $color-blue-900 !important;
}

.bg-blue-1000 {
    background: $color-blue-1000 !important;
}

.bg-white-100 {
    background: $color-white-100 !important;
}

.bg-orange-100 {
    background: $color-orange-100 !important;
}

.border-orange-100 {
    border: 1px solid $color-orange-100 !important;
}

.bg-green-100 {
    background: $color-green-100 !important;
}

// hovers
.hover:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5) !important;

}

.cursor {
    cursor: pointer !important;
}


.button-primary {
    padding: 10px;
    border-radius: 5px;
    outline: none !important;
    text-transform: capitalize;
    color: $color-white-100;
    cursor: pointer !important;
}

.btn-check:checked+.btn-outline-primary {
    background: $color-blue-100 !important;

    img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    }

    p {
        color: $color-white-100 !important;
    }
}

.btn-outline-primary:hover {
    background: $color-blue-100 !important;
    color: $color-white-100 !important;

    img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    }

    p {
        color: $color-white-100 !important;
    }
}

.optionSelected {
    background: $color-blue-100 !important;
    color: $color-white-100 !important;

    label {
        background: $color-blue-100 !important;
        color: $color-white-100 !important;
    }

    p {
        background: $color-blue-100 !important;
        color: $color-white-100 !important;
    }

    img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    }
}

.img-turn-white {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%) !important;
}

.badge-vertical {
    font-size: 11px;
    word-wrap: break-word;
    height: 100%;
    text-align: center;
    top: 0px;
    right: 0px;
    /* z-index: 2; */
    color: white;
    position: absolute !important;
    padding: 0 5px 0 5px;
    background: red;
    writing-mode: vertical-rl;
    border-radius: 0% 5% 5% 0%;
    text-orientation: mixed;
}

.footer {
    background: $color-blue-600;
}

.links-footer {
    color: white !important;
}
.links-footer:hover {
    color: $color-blue-100 !important;
}

.whatsapp-icon-home {
    background: green;
    padding: 13px;
    border-radius: 50%;
    color: white;
    cursor: pointer;
}

.button-primary:hover {
    background: $color-blue-200;
}

input.ng-invalid.ng-touched,
ng-select.ng-invalid.ng-touched,
select.ng-invalid.ng-touched {
    border: 1px solid red !important;
}

.ardilla-result-plan {
    position: absolute;
    top: 100px;
    left: 100px
}

.page-min-height {
    display: flex;
    align-items: center;
    // height: auto;
    min-height: 550px !important;
}

.ardilla-result-benefit {
    position: absolute;
    height: auto;
    z-index: 0;
    width: 100%;
    top: 155px;
    left: 0;
}

.ardilla-img-result {
    position: absolute;
    right: 0;
    z-index: 2
}

re-captcha.is-invalid>div {
    border: 1px solid #dc3545 !important;
    border-radius: 0.2rem;
}

p.description {
    font-size: 14px;
}

// medias query
@media only screen and (max-width: 1920px) {
    .ardilla-result-benefit {
        width: 1000px;
    }

    .ardilla-img-result {
        left: 100px
    }
}

// Extra extra large
@media only screen and (max-width: 1400px) {
    .ardilla-result-benefit {
        width: 865px;
    }

    .ardilla-img-result {
        left: 100px
    }
}

// Extra large
@media only screen and (max-width: 1200px) {
    .ardilla-result-benefit {
        width: 865px;
    }

    .ardilla-img-result {
        left: 80px
    }
}

// Large
@media only screen and (max-width: 992px) {
    .ardilla-result-benefit {
        width: 950px;
    }

    .ardilla-img-result {
        left: 50px
    }
}

// Medium
// Small
@media only screen and (max-width: 1920px) {
    .ardilla-result-plan {
        top: 100px;
        left: 100px
    }
}

@media only screen and (max-width: 1366px) {
    .ardilla-result-plan {
        top: 100px;
        left: -60px;
    }
}

@media only screen and (max-width: 400px) {
    h3 {
        font-size: 16px !important;
        margin-bottom: 0 !important;
        font-weight: bold !important;
    }

    h6,
    h5,
    h4,
    label,
    input,
    select,
    // a,   
    li,
    p,
    tr,
    td,
    span {
        font-weight: lighter !important;
        font-size: 12px !important;
        line-height: 1.5 !important;
        margin: 1px !important;
    }

    input[type="checkbox"] {
        margin-top: 6px !important;
    }

    .col-6 {
        margin-top: 0 !important;
    }

    img.img-fluid {
        max-width: 90px !important;
    }

    p.description {
        font-size: 9px !important;
        line-height: 1.3 !important;
    }

    .copyright {
        font-size: 8px !important;
    }

    .logo {
        width: 130px !important;
    }

    .whatsapp-icon-home {
        padding: 9px !important;
    }

    label.button-primary {
        height: auto !important;
    }

    .countryCoverageImage {
        width: 40px !important;
        padding: 0 !important;
    }

    .button-primary {
        padding: 9px !important;
        // height: 55px !important;
        font-size: 10px !important;
    }

    .contactBtn {
        height: auto !important;
    }

    .container-fluid {
        padding-top: 0 !important;
    }

    .nextStep {
        height: auto !important;
    }

    .relativesInsurance {
        height: 40px !important;
    }

    .relativesContainer {
        height: 90px !important;
    }

    .card {
        min-height: auto !important;
    }

    .insurerLogo {
        height: 50px !important;
    }

    .h-auto {
        height: auto !important;
    }

    .form-check {
        padding-left: 0 !important;
    }
}


// Height medias query



@media only screen and (max-height: 1080px) {
    .page-min-height {
        min-height: 73.5vh !important;
    }
}

@media only screen and (max-height: 900px) {
    .page-min-height {
        min-height: 71vh !important;
    }
}

@media only screen and (max-height: 768px) {
    .page-min-height {
        min-height: 68vh !important;
    }
}

@media only screen and (max-height: 720px) {
    .page-min-height {
        min-height: 65vh !important;
    }
}

// 


.ufcc {
    width: 100%;
    height: 100%;

    header {
        height: auto;

        .whatsapp-icon {
            color: white;
            background: rgb(2, 190, 2);
            border-radius: 50%;
            margin: 0;
            padding: 8px;
            display: inline-flex;
        }
    }

    .main {
        position: relative;
        min-height: calc(100% - 65px);
        overflow: auto;
        /*display: grid;*/
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        footer {
            /*align-self: end;*/
            min-height: 80px;
            flex-shrink: 0;
            background-color: rgba(15, 9, 9, .45);
        }
    }
}


/*::ng-deep{
    .toolbar.p-toolbar{
        position: relative;
        background-color: white;
        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
        z-index: 1001;
        padding: 1px 20px;
        border-radius: 0;
    }
}
.app-container {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    transition: width .3s cubic-bezier(.35,0,.25,1);
    
    .whatsapp-icon{
        color: white;
        background: rgb(2, 190, 2);
        border-radius: 50%;
        margin: 0;
        padding: 7px;
        display: inline-flex;
    }
    .spacer{
        flex: 1 1 auto;
    }
    .main{
        
        display: flex;
        justify-content: center;
    }
    footer{
        height: 80px;
        background-color: rgba(15, 9, 9, 0.45);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
}*/
/* You can add global styles to this file, and also import other style files */
html,
body {
    margin: auto;
    height: 100%;

    font-style: normal;
    font-variant-caps: normal;
    font-weight: 400;
    font-stretch: normal;
    font-size: 16px;
    line-height: 20px;
    font-size-adjust: none;
}

body {
    font-family: 'Open Sans', sans-serif !important;
}

.ufcmdl {
    top: -55px;

    @media (max-width: 690px) {
        top: -10px;
    }
}

.nm-1 {
    top: -40px;
}

.p-dialog {
    position: relative;
    width: 95%;
    max-width: 700px;
}

.p-dialog .p-dialog-content {
    padding: 0px;
    border-radius: 4px;
}

.p-dialog .p-dialog-header {
    color: red;
    padding: 2px 10px;
}

.p-slider .p-slider-handle {
    height: 0.9rem;
    width: 0.9rem;
    background: #6366F1;
}

.p-paginator {
    font-size: 12px;
}

.p-paginator .p-dropdown {
    height: auto;
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding: 5px;
    padding-right: 0px;
}

.pi {
    font-size: .85rem;
}

.p-checkbox,
.p-checkbox .p-checkbox-box {
    width: 18px;
    height: 18px;
}

.p-checkbox .p-checkbox-box {
    border-radius: 1px;
}

.card {
    background: var(--surface-e);
    /*padding: 2rem;*/
    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
    border-radius: 4px;
    margin-bottom: 2rem;
}

.img-wrap {
    display: inline-block;
    height: 300px;
    width: 300px;

    @media (max-width: 900px) {
        height: 250px;
        width: 250px;
    }

    @media (max-width: 768px) {
        height: 220px;
        width: 220px;
    }

    img {
        width: 100%;
        height: 100%;
    }
}



.text-section {
    @media (max-width: 768px) {
        text-align: center;
    }

    span {
        font-size: 2.4rem;
        line-height: 3.1rem;
        font-weight: lighter;
        color: #393838b0;

        @media (max-width: 1200px) {
            font-size: 2rem;
            line-height: 2.8rem;
        }

        @media (max-width: 992px) {
            font-size: 1.5rem;
            line-height: 2.2rem;
        }

        @media (max-width: 768px) {
            font-size: 1.2rem;
            line-height: 1.3rem;
        }
    }

    .sub-text {
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: #393838b0;

        @media (max-width: 1200px) {
            font-size: 1.2rem;
            line-height: 1.6rem;
        }

        @media (max-width: 768px) {
            font-size: 1rem;
            line-height: 1.2rem;
        }
    }
}

.chat-section {
    margin-top: 4rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    div {
        padding: 2px 10px;
    }

    .left-text {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .right-img {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: clip;
        margin-left: -10px;

        img {
            display: inline;
            margin: 0 auto;
            height: 121%;
            min-width: 121%;
        }
    }
}

.map-section {
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
}

.control-section {
    @media (max-width: 768px) {
        text-align: center;
    }
}

.ufc-btn-shd {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.ufc-btn {
    border: 0;
    white-space: nowrap;
    text-align: center;
    line-height: 36px;
    border-radius: 4px;
}

.home-btn {
    color: white;
    font-size: 18px;
    background-color: #f25a0fc4;
    margin: 10px;
    padding: 2px 35px;
    border-radius: 8px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);

    @media (max-width: 768px) {
        margin: 10px 7px;
        padding: 2px 30px;
        font-size: 16px;
    }

    @media (max-width: 600px) {
        padding: 2px 40px;
        font-size: 14px;
    }
}

.btn-pdl {
    padding: 2px 80px;
}

.btn-pdsq {
    padding: 22px 40px;
}

.ufcc-mdl {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);

    .modal-header {
        position: relative;
        display: flex;
        text-align: left;
        justify-content: space-between;
        border-bottom: 1px solid #dee2e6;
    }

    .modal-body {
        padding: 1rem;

        .mdl-text {
            .mn-txt {
                font-size: 25px;
                font-weight: bold;
                display: block;

                @media (max-width: 992px) {
                    font-size: 1.5rem;
                }

                @media (max-width: 768px) {
                    font-size: 1.2rem;
                }
            }

            .mn-subtxt {
                font-size: 17px;
                display: block;

                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }
        }

        .img-respon {
            width: 300px;
            height: 300px;

            @media (max-width: 768px) {
                width: 220px;
                height: 220px;
            }

            @media (max-width: 600px) {
                width: 200px;
                height: 200px;
            }

            @media (max-width: 500px) {
                width: 170px;
                height: 170px;
            }
        }
    }
}

.cmp-input {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.cmp-apn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.cmp-input:focus-visible {
    border: 1px solid #95bce3;
    outline: none;
}

.form-label {
    font-size: .85rem;
}

.alert {
    padding: 2px 0px;
    color: red;
    font-size: .8rem;
}

.control-with-img {
    display: flex;
    flex-direction: column;
    width: max-content;
    margin: auto;
}

.bg-blue {
    background: #b3deee;
}

.pld-txt {
    font-size: 14px;
    line-height: 20px;
    padding: 2px 5px !important;
}

.pld-er {
    background-color: rgb(227, 240, 248);
}

.pld-or {
    background-color: rgb(247, 249, 250);
}